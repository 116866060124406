import apiClientRequest from "@/common/lib/api";
export async function getSignedUrl(fileName) {
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "read";
  const {
    data: {
      signedUrl
    }
  } = await apiClientRequest.get("/gcs/signed-url", {
    params: {
      filename: fileName,
      action: action
    }
  });
  return signedUrl;
}
export async function createSyncEvent(_ref) {
  let {
    orderId,
    serviceCategoryId,
    fileName
  } = _ref;
  const payload = {
    order_id: orderId,
    service_category_id: serviceCategoryId,
    filename: fileName
  };
  const {
    data
  } = await apiClientRequest.post("/bounty/create-sync-event", payload);
  return data;
}