import apiClientRequest from "@/common/lib/api";
import localStorage from "@/common/lib/local-storage";
export async function getOrderList(searchQuery) {
  const {
    data
  } = await apiClientRequest.get("substrate/orders/list/".concat(localStorage.getAddress()), {
    params: {
      size: 1000,
      page: 1,
      keyword: searchQuery || ""
    }
  });
  return data;
}
export async function getOrderDetail(hash) {
  const {
    data
  } = await apiClientRequest.get("substrate/orders/".concat(hash));
  return data;
}
export async function fetchBountyLists(hash) {
  const {
    data: {
      data
    }
  } = await apiClientRequest.get("substrate/orders/bounty_list/".concat(hash));
  return data;
}
export async function fetchTxHashOrder(orderId) {
  const {
    data
  } = await apiClientRequest.get("transaction/hash", {
    params: {
      order_id: orderId
    }
  });
  return data;
}