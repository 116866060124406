const defaultState = {
  lab: null,
  products: {},
  order: {}
};
export default {
  namespaced: true,
  state: {
    ...defaultState
  },
  mutations: {
    SET_LAB(state, lab) {
      state.lab = lab;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    CLEAR_TEST_REQUEST(state) {
      state.lab = null;
      state.products = [];
    },
    SET_ORDER(state, order) {
      state.order = order;
    }
  },
  actions: {
    async getLab(_ref, lab) {
      let {
        commit
      } = _ref;
      commit("SET_LAB", lab);
    },
    async getProduct(_ref2, product) {
      let {
        commit
      } = _ref2;
      commit("SET_PRODUCTS", product);
    }
  }
};